/**
 * Internal Dependencies
 */
// Auth
import AuthSignIn from './AuthSignIn';
//import AuthSignUp from './AuthSignUp';
import AuthSignUpFailed from './AuthSignUpFailed';

// Start
import Dashboard from './Dashboard';

// App

import Customers from './Customers';
import Payments from './Payments';
import SinglePaymentRequests from './SinglePaymentRequests';
import PaymentLinks from './PaymentLinks';
import PaymentPlans from './PaymentPlans';
import RecurringPayments from './RecurringPayments';
import CustomerDetail from './CustomerDetail';
import PaymentRequestDetail from './PaymentRequestDetail';
import MembershipPayment from './MembershipPayment';
import PaymentDetail from './PaymentDetail';
import PaymentLinkDetail from './PaymentLinkDetail';
import PaymentPlanDetail from './PaymentPlanDetail';
import RecurringPaymentDetail from './RecurringPaymentDetail';
import BatchPaymentRequests from './BatchPaymentRequests';
import BacthPaymentRequestDetail from './BatchPaymentRequestDetail';
import Settings from './Settings';
import Billing from './Billing';
import Balance from './Balance';
import BalanceHistory from './BalanceHistory';
import AdminDashboard from './AdminDashboard';
import AdminCustomers from './AdminCustomers';
import AdminUsers from './AdminUsers';
import AdminWithdrawals from './AdminWithdrawals';
import AdminPayments from './AdminPayments';
import AdminSinglePaymentRequests from './AdminSinglePaymentRequests';
import AdminPaymentLinks from './AdminPaymentLinks';
import AdminPaymentPlans from './AdminPaymentPlans';
import AdminRecurringPayments from './AdminRecurringPayments';
import AdminCustomerDetail from './AdminCustomerDetail';
import AdminUserDetail from './AdminUserDetail';
import AdminPaymentDetail from './AdminPaymentDetail';
import AdminPaymentRequestDetail from './AdminPaymentRequestDetail';
import AdminPaymentLinkDetail from './AdminPaymentLinkDetail';
import AdminPaymentPlanDetail from './AdminPaymentPlanDetail';
import AdminRecurringPaymentDetail from './AdminRecurringPaymentDetail';
import AdminSettings from './AdminSettings';
import AdminWithdrawalDetail from './AdminWithdrawalDetail';
import MyQris from './MyQris';
import Suggestions from './Suggestions';
import Invoice from './Invoice';
import DigitalProducts from './DigitalProducts';
import DigitalProductDetail from './DigitalProductDetail';
import Webinars from './Webinars';
import WebinarDetail from './WebinarDetail';
import Affiliates from './Affiliates';
import AffiliateDetail from './AffiliateDetail';
import AffiliateRegister from './AffiliateRegister';
import AsAffiliates from './AsAffiliates';
import AffiliateProductDetail from './AffiliateProductDetail';
import DiscountCoupons from './DiscountCoupons';
import DiscountCouponDetail from './DiscountCouponDetail';
import Fundraisings from './Fundraisings';
import FundraisingDetail from './FundraisingDetail';
import PhysicalProducts from './PhysicalProducts';
import PhysicalProductDetail from './PhysicalProductDetail';
import Orders from './Orders';
import OrderDetail from './OrderDetail';
import Invoices from './Invoices';
import InvoiceDetail from './InvoiceDetail';
import QurbanDetail from './QurbanDetail';
import HomePage from './HomePage';
import Events from './Events';
import EventDetail from './EventDetail';
import LandingPages from './LandingPages';
import LandingPageEditor from './LandingPageEditor';
import LandingPageDetail from './LandingPageDetail';
import ReferAFriend from './ReferAFriend';
import Coachings from './Coachings';
import CoachingDetail from './CoachingDetail';
import OnlineCourses from './OnlineCourses';
import OnlineCourseDetail from './OnlineCourseDetail';
import Broadcasts from './Broadcasts';
import BroadcastDetail from './BroadcastDetail';
import BroadcastEmailEditor from './BroadcastEmailEditor';
import AutoResponders from './AutoResponders';
import Playground from './Playground';
import AnalyticsDashboard from './AnalyticsDashboard';
import AnalyticsReport from './AnalyticsReport';
import AnalyticsReportDetail from './AnalyticsReportDetail';
import Capital from './Capital';
import DebitCard from './DebitCard';
import BillPayments from './BillPayments';
import BillPaymentDetail from './BillPaymentDetail';
import BatchBillPayments from './BatchBillPayments';
import MoneyTransfers from './MoneyTransfers';
import MoneyTransferDetail from './MoneyTransferDetail';
import Employees from './Employees';
import EmployeeDetail from './EmployeeDetail';
import Payrolls from './Payrolls';
import PayrollDetail from './PayrollDetail';
import PayrollEmployeeDetail from './PayrollEmployeeDetail';
import BatchMoneyTransfers from './BatchMoneyTransfers';
import BatchMoneyTransfersDetail from './BatchMoneyTransfersDetail';
import CohortCourses from './CohortCourses';
import CohortCourseDetail from './CohortCourseDetail';
import StoreFront from './StoreFront';
import Installments from './Installments';
import InstallmentDetail from './InstallmentDetail';
import Ebooks from './Ebooks';
import EbookDetail from './EbookDetail';
import Podcasts from './Podcasts';
import PodcastDetail from './PodcastDetail';
import Audiobooks from './Audiobooks';
import AudiobookDetail from './AudiobookDetail';
import Bundles from './Bundles';
import BundleDetail from './BundleDetail';
import UnpaidTransactions from './UnpaidTransactions';
import Webhook from './Webhook';
import APIDeveloperKeys from './APIDeveloperKeys';
import Applications from './Applications';
import Crosssells from './Crosssells';
import CrosssellDetail from './CrosssellDetail';
import Trackers from './Trackers';
import SupportPage from './SupportPage';
import Memberships from './Memberships';
import MembershipDetail from './MembershipDetail';
import MembershipPaymentDetail from './MembershipPaymentDetail';
import WhatsappUnofficial from './WhatsappUnofficial';
import Writings from './Writings';
import WritingDetail from './WritingDetail';
import WhatsappOfficial from './WhatsappOfficial';
import AnalyticsOverview from './AnalyticsOverview';
import LicenseCodes from './LicenseCodes';
import LicenseCodeDetail from './LicenseCodeDetail';
import Zapier from './Zapier';
import ProductTags from './ProductTags';
import SimpleLandingPages from './SimpleLandingPages';
import SimpleLandingPageDetail from './SimpleLandingPageDetail';
import SimpleLandingPageEditor from './SimpleLandingPageEditor';
import CustomerSegment from './CustomerSegment';
import TelegramNotification from './TelegramNotification';

export default {
  '/sign-in': AuthSignIn,
  '/sign-in/coupon/:id': AuthSignIn,
  '/sign-in/bizdev/:id': AuthSignIn,
  '/sign-in/referral/:id': AuthSignIn,
  '/sign-in/sub/:id': AuthSignIn,
  //'/sign-up': AuthSignUp,
  '/sign-up-failed': AuthSignUpFailed,

  '/': Dashboard,
  '/customers': Customers,
  '/payments': Payments,
  '/orders': Orders,
  '/single-payment-requests': SinglePaymentRequests,
  '/invoices': Invoices,
  '/installments': Installments,
  '/installment-detail/:id': InstallmentDetail,
  '/payment-link': PaymentLinks,
  '/payment-plans': PaymentPlans,
  '/recurring-payments': RecurringPayments,
  '/customer-detail/:id': CustomerDetail,
  '/payment-request-detail/:id': PaymentRequestDetail,
  '/membership-payment/:id': MembershipPayment,
  '/invoice-detail/:id': InvoiceDetail,
  '/payment-detail/:id': PaymentDetail,
  '/order-detail/:id': OrderDetail,
  '/payment-link-detail/:id': PaymentLinkDetail,
  '/payment-plan-detail/:id': PaymentPlanDetail,
  '/recurring-payment-detail/:id': RecurringPaymentDetail,
  '/batch-payment-requests': BatchPaymentRequests,
  '/batch-payment-request-detail/:id': BacthPaymentRequestDetail,
  '/settings': Settings,
  '/billing': Billing,
  '/balance': Balance,
  '/balance-history': BalanceHistory,
  '/my-qris': MyQris,
  '/homepage': HomePage,
  '/suggestions': Suggestions,
  '/affiliates': Affiliates,
  '/affiliate-detail/:id': AffiliateDetail,
  '/affiliate-register/:id': AffiliateRegister,
  '/as-affiliate': AsAffiliates,
  '/affiliate-product-detail/:id': AffiliateProductDetail,
  '/diskon': DiscountCoupons,
  '/discount-detail/:id': DiscountCouponDetail,
  '/bundling': Bundles,
  '/bundling-detail/:id': BundleDetail,

  // Admin
  '/admin': AdminDashboard,
  '/admin/customers': AdminCustomers,
  '/admin/users': AdminUsers,
  '/admin/withdrawals': AdminWithdrawals,
  '/admin/payments': AdminPayments,
  '/admin/payment-requests': AdminSinglePaymentRequests,
  '/admin/payment-links': AdminPaymentLinks,
  '/admin/payment-plans': AdminPaymentPlans,
  '/admin/recurring-payments': AdminRecurringPayments,
  '/admin/customer-detail': AdminCustomerDetail,
  '/admin/user-detail': AdminUserDetail,
  '/admin/payment-detail': AdminPaymentDetail,
  '/admin/payment-request-detail': AdminPaymentRequestDetail,
  '/admin/payment-link-detail': AdminPaymentLinkDetail,
  '/admin/payment-plan-detail': AdminPaymentPlanDetail,
  '/admin/recurring-payment-detail': AdminRecurringPaymentDetail,
  '/admin/settings': AdminSettings,
  '/admin/withdrawal-detail': AdminWithdrawalDetail,

  // Invoice
  '/invoice/:id': Invoice,

  // Digital product
  '/digital-products': DigitalProducts,
  '/digital-product-detail/:id': DigitalProductDetail,

  // Physical product
  '/physical-products': PhysicalProducts,
  '/physical-product-detail/:id': PhysicalProductDetail,

  // Webinars
  '/webinars': Webinars,
  '/webinar-detail/:id': WebinarDetail,

  // Events
  '/events': Events,
  '/event-detail/:id': EventDetail,

  // E-book
  '/ebooks': Ebooks,
  '/ebook-detail/:id': EbookDetail,

  //Writings
  '/writings': Writings,
  '/writing-detail/:id': WritingDetail,

  // Podcast
  '/podcasts': Podcasts,
  '/podcast-detail/:id': PodcastDetail,

  // Audiobook
  '/audiobooks': Audiobooks,
  '/audiobook-detail/:id': AudiobookDetail,

  //landing page
  '/landing-pages': LandingPages,
  '/landing-page-editor/:id': LandingPageEditor,
  '/landing-page-detail/:id': LandingPageDetail,

  //simple landing page
  '/simple-landing-pages': SimpleLandingPages,
  '/simple-landing-page-detail/:id': SimpleLandingPageDetail,
  '/simple-landing-page-editor/:id': SimpleLandingPageEditor,

  //refer-a-friend
  '/refer-a-friend': ReferAFriend,

  // Broadcast
  '/broadcasts': Broadcasts,
  '/customer-segment': CustomerSegment,
  '/broadcast-detail/:id': BroadcastDetail,
  '/broadcast-email-editor/:id': BroadcastEmailEditor,

  // Auto responder
  '/autoresponders': AutoResponders,

  // Mayar capital
  '/capital': Capital,

  // Debit card
  '/debit-card': DebitCard,

  // Fundraising
  '/fundraisings': Fundraisings,
  '/fundraising-detail/:id': FundraisingDetail,
  '/qurban-detail/:id': QurbanDetail,
  '/waqaf-detail/:id': FundraisingDetail,

  // Coaching
  '/coachings': Coachings,
  '/coaching-detail/:id': CoachingDetail,

  '/playground': Playground,

  // Online courses
  '/online-courses': OnlineCourses,
  '/online-course-detail/:id': OnlineCourseDetail,

  //analytics
  '/analytics-overview': AnalyticsOverview,
  '/detail-analytics': AnalyticsDashboard,
  '/reports': AnalyticsReport,
  '/reports/detail': AnalyticsReportDetail,

  '/bill-payments': BillPayments,
  '/bill-payment-detail/:id': BillPaymentDetail,
  '/mass-bill-payments': BatchBillPayments,
  '/money-transfers': MoneyTransfers,
  '/money-transfer-detail/:id': MoneyTransferDetail,

  // Payroll
  '/employees': Employees,
  '/employee-detail/:id': EmployeeDetail,
  '/payroll': Payrolls,
  '/payroll-detail/:id': PayrollDetail,
  '/payroll-employee-detail/:id': PayrollEmployeeDetail,

  // Send Money
  '/mass-money-transfers': BatchMoneyTransfers,
  '/mass-money-transfers-detail/:id': BatchMoneyTransfersDetail,

  // Cohort Based
  '/cohort-courses': CohortCourses,
  '/cohort-course-detail/:id': CohortCourseDetail,

  // Membership
  '/memberships': Memberships,
  '/membership-detail/:id': MembershipDetail,

  '/membership-payment-detail/:id': MembershipPaymentDetail,

  // Developer tools
  '/webhook': Webhook,
  '/api-keys': APIDeveloperKeys,
  '/applications': Applications,
  '/zapier': Zapier,
  '/trackers': Trackers,
  '/whatsapp-unofficial': WhatsappUnofficial,
  '/whatsapp-official': WhatsappOfficial,
  '/telegram-notification': TelegramNotification,

  // Store Front
  '/storefront': StoreFront,
  '/tags': ProductTags,

  '/unpaid-transactions': UnpaidTransactions,

  // Crosssell
  '/crosssells': Crosssells,
  '/crosssell-detail/:id': CrosssellDetail,

  //support page MAYAR.GG
  '/support-page': SupportPage,

  //License Code Product
  '/license-codes': LicenseCodes,
  '/license-code-detail/:id': LicenseCodeDetail
};
