import { useMemo } from 'react';
import { useRegisterActions } from 'kbar';

// need to get data
const parentId = {
  feature: 'featureId',
  askPayments: 'askPayId',
  finance: 'financeId',
  billId: 'billId',
  sendMoney: 'sendMoneyId',
  affiliateRoot: 'affiliateId',
  saldoRoot: 'saldoId',
  integrateRoot: 'intergatedId',
  searchProductRoot: 'searchProuctRootid',
  searchTransactionRoot: 'searchTransactionRoot'
};

const entityTypes = {
  // nProduct: 'nama produk',
  askPay: 'Permintaan Pembayaran',
  finansial: 'finansial',
  marketing: 'marketing',
  myAccount: 'akun saya'
};

export function useSearchActions(paymentLinks, transaction) {
  const searchPayments = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'tagih',
      parent: parentId.askPayments,
      name: 'Penagihan',
      keywords: 'Penagihan',
      section: entityTypes.askPay,
      perform: () => (window.location.pathname = '/single-payment-requests')
    });
    actions.push({
      id: 'tagihMassal',
      parent: parentId.askPayments,
      name: 'Penagihan Massal',
      keywords: 'penagihan massal',
      section: entityTypes.askPay,
      perform: () => (window.location.pathname = '/batch-payment-requests')
    });
    actions.push({
      id: 'invoices',
      parent: parentId.askPayments,
      name: 'Invoices',
      keywords: 'invoices',
      section: entityTypes.askPay,
      perform: () => (window.location.pathname = '/invoices')
    });
    actions.push({
      id: 'cicilan',
      parent: parentId.askPayments,
      name: 'cicilan',
      keywords: 'cicilan',
      section: entityTypes.askPay,
      perform: () => (window.location.pathname = '/installments')
    });
    return actions;
  }, []);

  // const searchActions = useMemo(() => {
  //   const actions = [];
  //   actions.push({
  //     id: 'lb',
  //     parent: parentId.feature,
  //     name: 'Link bokep',
  //     keywords: 'link bokep',
  //     shortcut: ["wo"],
  //     // section: entityTypes.nProduct,
  //     perform: () => (window.location.pathname = "/google.com"),
  //   });
  //   return actions;
  // }, []);

  const searchPayroll = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'karyawan',
      parent: parentId.finance,
      name: 'Data Karyawan',
      keywords: 'karyawan',
      // shortcut: ["wo"],
      section: 'payroll',
      perform: () => (window.location.pathname = '/employees')
    });
    actions.push({
      id: 'karyawan',
      parent: parentId.finance,
      name: 'Pembayaran Gaji',
      keywords: 'Pembayaran Gaji',
      // shortcut: ["wo"],
      section: 'payroll',
      perform: () => (window.location.pathname = '/payroll')
    });
    return actions;
  }, []);
  const searchBillPay = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'payInvoice',
      parent: parentId.billId,
      name: 'Pembayaran Invoice',
      keywords: 'Pembayaran Invoice',
      // shortcut: ["wo"],
      section: 'bill payments',
      perform: () => (window.location.pathname = '/bill-payments')
    });
    actions.push({
      id: 'massPay',
      parent: parentId.billId,
      name: 'Pembayaran Massal',
      keywords: 'Pembayaran Massal',
      // shortcut: ["wo"],
      section: 'bill payments',
      perform: () => (window.location.pathname = '/mass-bill-payments')
    });
    return actions;
  }, []);

  const sendMoneyData = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'sendMoney',
      parent: parentId.sendMoney,
      name: 'Kirim Uang',
      keywords: 'kirim uang',
      // shortcut: ["wo"],
      section: 'kirim uang',
      perform: () => (window.location.pathname = '/money-transfers')
    });
    actions.push({
      id: 'sendMoney',
      parent: parentId.sendMoney,
      name: 'Kirim Uang Massal',
      keywords: 'Kirim Uang Massal',
      // shortcut: ["wo"],
      section: 'kirim uang',
      perform: () => (window.location.pathname = '/mass-money-transfers')
    });
    return actions;
  }, []);

  const searchAffiliate = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'memberAffilate',
      parent: parentId.affiliateRoot,
      name: 'Anggota Affiliates',
      keywords: 'Anggota Affiliates',
      // shortcut: ["wo"],
      section: 'affiliate',
      perform: () => (window.location.pathname = '/affiliates')
    });
    actions.push({
      id: 'asAffiliate',
      parent: parentId.affiliateRoot,
      name: 'Sebagai Affiliator',
      keywords: 'Sebagai Affiliator',
      // shortcut: ["wo"],
      section: 'affiliate',
      perform: () => (window.location.pathname = '/as-affiliate')
    });

    return actions;
  }, []);
  const searchBalance = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'topUp',
      parent: parentId.saldoRoot,
      name: 'Penarikan & Top Up',
      keywords: 'Penarikan & Top Up',
      // shortcut: ["wo"],
      section: 'saldo',
      perform: () => (window.location.pathname = '/balance')
    });
    actions.push({
      id: 'balHistory',
      parent: parentId.saldoRoot,
      name: 'History',
      keywords: 'History',
      // shortcut: ["wo"],
      section: 'saldo',
      perform: () => (window.location.pathname = '/balance-history')
    });

    return actions;
  }, []);
  const searchIntegrasi = useMemo(() => {
    const actions = [];
    actions.push({
      id: 'apiKeys',
      parent: parentId.integrateRoot,
      name: 'API Keys & Token',
      keywords: 'api',
      // shortcut: ["wo"],
      section: 'integrasi',
      perform: () => (window.location.pathname = '/api-keys')
    });
    actions.push({
      id: 'webhook',
      parent: parentId.integrateRoot,
      name: 'Webhook',
      keywords: 'Webhook',
      // shortcut: ["wo"],
      section: 'integrasi',
      perform: () => (window.location.pathname = '/webhook')
    });
    actions.push({
      id: 'telegram',
      parent: parentId.integrateRoot,
      name: 'Telegram Notification',
      keywords: 'telegram',
      // shortcut: ["wo"],
      section: 'integrasi',
      perform: () => (window.location.pathname = '/telegram')
    });
    actions.push({
      id: 'applikasiIntegrate',
      parent: parentId.integrateRoot,
      name: 'Applikasi',
      keywords: 'applikasi',
      // shortcut: ["wo"],
      section: 'integrasi',
      perform: () => (window.location.pathname = '/applications')
    });
    return actions;
  }, []);

  const SearchProduct = useMemo(() => {
    // const actions = [];
    const actions =
      paymentLinks?.map(aya => {
        if (aya.type === 'digital_product') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'digital produk',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'ebook') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'ebook',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'podcast') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'podcast',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'audiobook') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'audiobook',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'webinar') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'webinar',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'coaching') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'coaching',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'event') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'event & acara',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'cohort_based') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'Kelas cohort-based',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'course') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'Kelas Online (OD)',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'physical_product') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'produk fisik',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'generic_link') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'link pembayaran',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'fundraising') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'penggalangan dana',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
        if (aya.type === 'subscription') {
          return {
            id: aya.id,
            parent: parentId.searchProductRoot,
            // subtitle:"ah",
            name: aya.name,
            keywords: aya.name,
            section: 'paket berlangganan',
            perform: () => (window.location.pathname = `/digital-product-detail/${aya.id}`)
          };
        }
      }) || [];

    return actions;
  }, [paymentLinks]);

  const searchTransaction = useMemo(() => {
    const actions =
      transaction?.map(transaksi => {
        return {
          id: transaksi.id,
          parent: parentId.searchTransactionRoot,
          // subtitle:"ah",
          name: transaksi?.paymentLink?.name ? transaksi?.paymentLink?.name : 'Nama transaksi tidak terinput',
          keywords: transaksi.id,
          section: 'Transaksi Detail',
          perform: () => (window.location.pathname = `/payment-detail/${transaksi.id}`)
        };
      }) || [];

    return actions;
  }, [transaction]);
  // ROOT
  const rootSearchPay = useMemo(
    () =>
      searchPayments.length
        ? {
            id: parentId.askPayments,
            name: 'Permintaan Pembayaran..',
            shortcut: ['p', 'p'],
            keywords: 'Permintaan',
            icon: 'send',
            section: 'navigation'
          }
        : null,
    [searchPayments]
  );

  // const rootSearchAction = useMemo(
  //   () =>
  //     searchActions.length
  //        {
  //           id: parentId.feature,
  //           name: 'Search Feature..',
  //           // shortcut: ['Fu'],
  //           keywords: 'feature',
  //           section: 'Feature',
  //         }
  //       : null,
  //   [searchActions]
  // );

  const rootPayroll = useMemo(
    () =>
      searchPayroll.length
        ? {
            id: parentId.finance,
            name: 'Payroll..',
            keywords: 'Payroll',
            shortcut: ['pr'],
            section: entityTypes.finansial,
            icon: 'feather'
          }
        : null,
    [searchPayroll]
  );
  const rootBill = useMemo(
    () =>
      searchBillPay.length
        ? {
            id: parentId.billId,
            name: 'Bill Payments..',
            keywords: 'bill payment',
            icon: 'check-circle',
            shortcut: ['b', 'p'],
            section: entityTypes.finansial
          }
        : null,
    [searchBillPay]
  );
  const rootSendMoney = useMemo(
    () =>
      sendMoneyData.length
        ? {
            id: parentId.sendMoney,
            name: 'Kirim Uang..',
            keywords: 'kirim uang',
            shortcut: ['k', 'u'],
            icon: 'arrow-up-right',
            section: entityTypes.finansial
          }
        : null,
    [sendMoneyData]
  );
  const rootAffilate = useMemo(
    () =>
      searchAffiliate.length
        ? {
            id: parentId.affiliateRoot,
            name: 'Affiliate..',
            keywords: 'affiliate',
            section: entityTypes.marketing,
            icon: 'award',
            shortcut: ['a', 'e']
          }
        : null,
    [searchAffiliate]
  );
  const rootSaldo = useMemo(
    () =>
      searchBalance.length
        ? {
            id: parentId.saldoRoot,
            name: 'Saldo..',
            keywords: 'saldo',
            section: entityTypes.myAccount,
            shortcut: ['so'],
            icon: 'dollar-sign'
          }
        : null,
    [searchBalance]
  );
  const rootIntegrasi = useMemo(
    () =>
      searchIntegrasi.length
        ? {
            id: parentId.integrateRoot,
            name: 'Integrasi..',
            keywords: 'integrasi',
            section: entityTypes.myAccount,
            icon: 'code',
            shortcut: ['ig']
          }
        : null,
    [searchIntegrasi]
  );
  const rootSearchProduct = useMemo(
    () =>
      searchIntegrasi.length
        ? {
            id: parentId.searchProductRoot,
            name: 'Cari Produk...',
            keywords: 'cari produk',
            section: 'Cari..'
          }
        : null,
    [SearchProduct]
  );
  const rootSearchTransaction = useMemo(
    () =>
      searchIntegrasi.length
        ? {
            id: parentId.searchTransactionRoot,
            name: 'Cari Transaksi by ID... ',
            keywords: 'cari transaksi',
            section: 'Cari..'
          }
        : null,
    [searchTransaction]
  );
  // useRegisterActions([rootSearchAction, ...searchActions].filter(Boolean));

  useRegisterActions([rootSearchPay, ...searchPayments].filter(Boolean));
  useRegisterActions([rootPayroll, ...searchPayroll].filter(Boolean));
  useRegisterActions([rootBill, ...searchBillPay].filter(Boolean));
  useRegisterActions([rootSendMoney, ...sendMoneyData].filter(Boolean));
  useRegisterActions([rootAffilate, ...searchAffiliate].filter(Boolean));
  useRegisterActions([rootSaldo, ...searchBalance].filter(Boolean));
  useRegisterActions([rootIntegrasi, ...searchIntegrasi].filter(Boolean));
  useRegisterActions([rootSearchProduct, ...SearchProduct].filter(Boolean), [paymentLinks]);
  useRegisterActions([rootSearchTransaction, ...searchTransaction].filter(Boolean), [transaction]);
}
