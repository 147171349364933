/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';
import { GQL_PAYMENTLINK_GET_WITH_TRANSACTIONS } from '../../gqls/payment-links';
import { paymentLinkDetails } from '../../actions/payment-links';

import { updatePaginatedData } from '../../utils';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const CrosssellDetail = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);
  const { id } = useParams();
  const gparam = {
    search: { id: [{ operator: 'eq', value: id }], isCrossSell: [{ operator: 'eq', value: 'true' }] }
  };

  const { loading, error, data, fetchMore } = useQuery(GQL_PAYMENTLINK_GET_WITH_TRANSACTIONS, {
    variables: gparam
  });

  const pl = _.get(data, 'getPaymentLinkPage.items[0]');
  if (pl) {
    dispatch(paymentLinkDetails(pl));
  }

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl]);

  if (error) return `Error! ${error.message}`;

  if (loading) return <Spinner />;

  if (data && data.getPaymentLinkPage && data.getPaymentLinkPage.items.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser?.name || ' ',
          '/crosssells': 'Cross-sell Produk'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>Cross-sell {pl && pl.link}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default CrosssellDetail;
