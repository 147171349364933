import { isPublicPage } from './utils';

/**
 * App Settings
 */

const settings = {
  night_mode: isPublicPage() ? false : JSON.parse(window.localStorage.getItem('darkMode')) || false,
  spotlight_mode: false,
  show_section_lines: true,
  sidebar_dark: false,
  sidebar_static: false,
  sidebar_small: false,
  sidebar_effect: 'shrink',
  nav: true,
  nav_dark: false,
  nav_logo_path: require('../assets/images/logo.svg'),
  nav_logo_white_path: require('../assets/images/logo-white.svg'),
  logo_mobile: require('../assets/images/mayarlogo-small.png'),
  nav_logo_width: '45px',
  logo_mobile_width: '85px',
  logo_mobile_height: '30px',
  nav_logo_url: '/',
  nav_align: 'left',
  nav_expand: 'lg',
  nav_sticky: true,
  nav_autohide: true,
  nav_container_fluid: true,
  home_url: '/',
  navigation: {
    '#actions': {
      name: 'Actions',
      icon: 'layers',
      sub: {
        '#create-post': {
          name: 'Create Post',
          icon: 'plus-circle'
        },
        '#create-page': {
          name: 'Create Page',
          icon: 'plus-circle'
        },
        '#manage-users': {
          name: 'Manage Users',
          icon: 'users'
        },
        '#manage-sites': {
          name: 'Manage Sites',
          icon: 'sidebar',
          sub: {
            '#my-site-1': {
              name: 'My Site 1'
            },
            '#my-site-2': {
              name: 'My Site 2'
            },
            '#my-site-3': {
              name: 'My Site 3'
            }
          }
        }
      }
    }
  },
  navigation_sidebar: {
    '/': {
      name: 'Beranda',
      icon: 'home'
    },

    // Apps
    '/customers': {
      name: 'Pelanggan',
      icon: 'users'
    },
    '/payments': {
      name: 'Transaksi',
      icon: 'layers'
    },
    '/recurring-payments': {
      name: 'Berlangganan',
      icon: 'repeat'
    },
    '/orders': {
      name: 'Order',
      icon: 'shopping-bag'
    },
    '/payment-requests': {
      name: 'Permintaan Pembayaran',
      icon: 'send',
      sub: {
        '/single-payment-requests': {
          name: 'Penagihan'
        },
        '/batch-payment-requests': {
          name: 'Penagihan Massal'
        },
        '/invoices': {
          name: 'Invoice'
        },
        '/installments': {
          name: 'Cicilan'
        }
      }
    },
    '/payment-link': {
      label: 'Produk',
      name: 'Link Pembayaran',
      icon: 'link'
    },
    '/physical-products': {
      name: 'Produk Fisik',
      icon: 'tag'
    },
    '/digital-products': {
      name: 'Produk Digital',
      icon: 'download-cloud'
    },
    '/online-courses': {
      name: 'Kelas Online (OD)',
      icon: 'book-open'
    },
    '/cohort-courses': {
      name: 'Kelas Cohort / Bootcamp',
      icon: 'grid'
    },
    '/webinars': {
      name: 'Webinar',
      icon: 'video'
    },
    '/events': {
      name: 'Event & Acara',
      icon: 'aperture'
    },
    '/coachings': {
      name: 'Coaching & Mentoring',
      icon: 'watch'
    },
    '/fundraisings': {
      name: 'Penggalangan Dana',
      icon: 'heart'
    },
    '/payment-plans': {
      name: 'Paket Berlangganan',
      icon: 'calendar'
    },
    '/ebooks': {
      name: 'E-Book',
      icon: 'eye'
    },
    '/podcasts': {
      name: 'Podcast',
      icon: 'mic'
    },
    '/audiobooks': {
      name: 'Audio Book',
      icon: 'headphones'
    },
    '/writings': {
      name: 'Tulisan',
      icon: 'pen-tool'
    },
    '/support-page': {
      name: 'Creator Support Page',
      icon: 'smile'
    },
    '/memberships': {
      name: 'Membership & SaaS',
      icon: 'award'
    },
    '/license-codes': {
      name: 'Lisensi Software',
      icon: 'key'
    },
    '/debit-card': {
      label: 'Finansial',
      name: 'Kartu Debit',
      icon: 'credit-card'
    },
    '/payroll-overview': {
      name: 'Payroll',
      icon: 'feather',
      sub: {
        '/employees': {
          name: 'Data Karyawan'
        },
        '/payroll': {
          name: 'Pembayaran Gaji'
        }
      }
    },
    '/financial-overview': {
      name: 'Bill Payment',
      icon: 'check-circle',
      sub: {
        '/bill-payments': {
          name: 'Pembayaran Invoice'
        },
        '/mass-bill-payments': {
          name: 'Pembayaran Massal'
        }
      }
    },
    '/money-transfers-overview': {
      name: 'Kirim Uang',
      icon: 'arrow-up-right',
      sub: {
        '/money-transfers': {
          name: 'Kirim Uang'
        },
        '/mass-money-transfers': {
          name: 'Kirim Uang Massal'
        }
      }
    },
    '/capital': {
      name: 'Capital / Pendanaan',
      icon: 'briefcase'
    },
    '/storefront-overview': {
      name: 'Storefront',
      label: 'Marketing',
      icon: 'layout',
      sub: {
        '/storefront': {
          name: 'Storefront'
        },
        '/tags': {
          name: 'Tags'
        }
      }
    },
    '/broadcasts-overview': {
      name: 'Broadcast',
      icon: 'rss',
      sub: {
        '/broadcasts': {
          name: 'Broadcast'
        },
        '/customer-segment': {
          name: 'Segmen Pelanggan'
        }
      }
    },
    '/autoresponders': {
      name: 'Auto Responder',
      icon: 'target'
    },
    '/homepage': {
      name: 'Link Bio & Katalog',
      icon: 'bookmark'
    },
    '/landing-page-overview': {
      name: 'Landing Page',
      icon: 'airplay',
      sub: {
        '/simple-landing-pages': {
          name: 'Simple Landing Page'
        },
        '/landing-pages': {
          name: 'Advance Landing Page'
        }
      }
    },
    '/affiliates': {
      name: 'Affiliate',
      icon: 'award'
    },
    '/as-affiliate': {
      name: 'Produk Affiliate',
      icon: 'Award'
    },
    '/diskon': {
      name: 'Diskon dan Kupon',
      icon: 'percent'
    },
    '/bundling': {
      name: 'Bundling',
      icon: 'box'
    },
    '/crosssells': {
      name: 'Cross-sell',
      icon: 'arrow-up-circle'
    },
    '/balance-overview': {
      label: 'Akun Saya',
      name: 'Saldo',
      icon: 'dollar-sign',
      sub: {
        '/balance': {
          name: 'Penarikan & Top Up'
        },
        '/balance-history': {
          name: 'Histori'
        }
      }
    },
    '/analytics': {
      name: 'Analytics',
      icon: 'bar-chart',
      sub: {
        '/analytics-overview': {
          name: 'Overview'
        },
        '/detail-analytics': {
          name: 'Detail Analytics'
        }
      }
    },
    '/my-qris': {
      name: 'My Qris',
      icon: 'maximize'
    },
    '/settings': {
      name: 'Pengaturan',
      icon: 'settings'
    },
    '/developer-tools': {
      name: 'Integrasi',
      icon: 'code',
      sub: {
        '/api-keys': {
          name: 'API Keys & Token'
        },
        '/webhook': {
          name: 'Webhook'
        },
        '/whatsapp-unofficial': {
          name: 'Whatsapp Unofficial'
        },
        '/whatsapp-official': {
          name: 'Whatsapp Official'
        },
        '/telegram-notification': {
          name: 'Telegram Notification'
        },
        '/zapier': {
          name: 'Zapier'
        },
        '/applications': {
          name: 'No-Code'
        }
      }
    },
    '/billing': {
      name: 'Upgrade ke Mayar Pro',
      icon: 'zap'
    },
    '/refer-a-friend': {
      name: 'Refer a Friend',
      icon: 'gift'
    }
    // '/suggestions': {
    //   name: 'Kritik dan Saran',
    //   icon: 'message-square'
    // }

    //'/admin': {
    //  label: 'Navigasi Sementara',
    //  name: 'Ke Halaman Admin',
    //  icon: 'lock',
    //},
  },
  navigation_sidebar_admin: {
    '/admin': {
      name: 'Dashboard',
      icon: 'codesandbox'
    },

    // Apps
    '/admin/withdrawals': {
      name: 'Penarikan Dana',
      icon: 'dollar-sign'
    },
    '/admin/payments': {
      name: 'Pembayaran',
      icon: 'layers'
    },
    '/admin/payment-requests': {
      name: 'Tagihan',
      icon: 'send'
    },
    '/admin/payment-links': {
      name: 'Link Pembayaran',
      icon: 'link'
    },
    '/admin/payment-plans': {
      name: 'Paket Berlangganan',
      icon: 'calendar'
    },
    '/admin/recurring-payments': {
      name: 'Berlangganan',
      icon: 'repeat'
    },
    '/admin/users': {
      label: 'Master Data',
      name: 'Pengguna',
      icon: 'user-check'
    },
    '/admin/customers': {
      name: 'Pembeli (Pelanggan)',
      icon: 'users'
    },
    '/admin/settings': {
      label: 'Platform / Aplikasi',
      name: 'Pengaturan',
      icon: 'settings'
    },
    '/': {
      label: 'Navigasi Sementara',
      name: 'Ke Halaman Pengguna',
      icon: 'lock'
    }
  },
  breadcrumbs_presets: {
    apps: {
      '/mailbox': 'Mailbox',
      '/messenger': 'Messenger',
      '/calendar': 'Calendar',
      '/project-management': 'Project Management',
      '/file-manager': 'File Manager',
      '/profile': 'Profile'
    },
    content: {
      '/grid': 'Grid',
      '/colors': 'Colors',
      '/typography': 'Typography',
      '/component-table': 'Tables'
    },
    components_base: {
      '/component-alert': 'Alert',
      '/component-badge': 'Badge',
      '/component-breadcrumbs': 'Breadcrumbs',
      '/component-button': 'Button',
      '/component-card': 'Card',
      '/component-carousel': 'Carousel',
      '/component-collapse': 'Collapse',
      '/component-dropdown': 'Dropdown',
      '/component-list-group': 'List Group',
      '/component-media-object': 'Media Object',
      '/component-modal': 'Modal',
      '/component-nav': 'Nav',
      '/component-pagination': 'Pagination',
      '/component-popover': 'Popover',
      '/component-progress': 'Progress',
      '/component-spinner': 'Spinner',
      '/component-tabs': 'Tabs'
    },
    components_advanced: {
      '/component-icons-feather': 'Icons: Feather',
      '/component-icons-fontawesome': 'Icons: Font Awesome',
      '/component-charts-chartjs': 'Charts: Chart.js',
      '/component-charts-chartist': 'Charts: Chartist',
      '/component-charts-peity': 'Charts: Peity',
      '/component-charts-echarts': 'Charts: eCharts',
      '/component-charts-flot': 'Charts: Flot',
      '/component-timeline': 'Timeline',
      '/component-tree-view': 'Tree View',
      '/component-toast': 'Toast',
      '/component-sweet-alert': 'Sweet Alert',
      '/component-maps': 'Maps',
      '/component-data-tables': 'Data Tables'
    },
    forms_advanced: {
      '/forms-datetime': 'DateTime Picker',
      '/forms-validation': 'Validation',
      '/forms-touch-spin': 'Touch Spin',
      '/forms-range-slider': 'Range Slider',
      '/forms-input-masks': 'Input Masks',
      '/forms-dropzone': 'Dropzone',
      '/forms-colorpicker': 'Color Picker',
      '/forms-select': 'Select',
      '/forms-markdown': 'Markdown editor',
      '/forms-wysiwyg': 'WYSIWYG editor'
    }
  },
  img_country: {
    usa: require('../assets/images/flags/united-states-of-america.svg'),
    china: require('../assets/images/flags/china.svg'),
    germany: require('../assets/images/flags/germany.svg'),
    japan: require('../assets/images/flags/japan.svg'),
    spain: require('../assets/images/flags/spain.svg'),
    france: require('../assets/images/flags/france.svg'),
    canada: require('../assets/images/flags/canada.svg'),
    netherlands: require('../assets/images/flags/netherlands.svg'),
    italy: require('../assets/images/flags/italy.svg'),
    russia: require('../assets/images/flags/russia.svg'),
    czech_republic: require('../assets/images/flags/czech-republic.svg')
  },
  img_file: {
    empty: require('../assets/images/icon-empty.svg'),
    zip: require('../assets/images/icon-zip.svg'),
    rar: require('../assets/images/icon-rar.svg'),
    html: require('../assets/images/icon-html.svg'),
    php: require('../assets/images/icon-php.svg'),
    css: require('../assets/images/icon-css.svg'),
    js: require('../assets/images/icon-js.svg'),
    doc: require('../assets/images/icon-doc.svg'),
    txt: require('../assets/images/icon-txt.svg'),
    pdf: require('../assets/images/icon-pdf.svg'),
    xls: require('../assets/images/icon-xls.svg'),
    png: require('../assets/images/icon-png.svg'),
    jpg: require('../assets/images/icon-jpg.svg')
  },
  users: [
    {
      img: require('../assets/images/avatar-1.png'),
      img_profile: require('../assets/images/avatar-1-profile.png'),
      name: 'Jack Boyd'
    },
    {
      img: require('../assets/images/avatar-2.png'),
      name: 'Helen Holt'
    },
    {
      img: require('../assets/images/avatar-3.png'),
      name: 'Avice Harris'
    },
    {
      img: require('../assets/images/avatar-4.png'),
      name: 'Anna Rice'
    },
    {
      img: require('../assets/images/avatar-5.png'),
      name: 'Amber Smith'
    },
    {
      img: require('../assets/images/avatar-6.png'),
      name: 'Mary Rose'
    }
  ],
  letters: [
    {
      img: require('../assets/images/letter-1.png'),
      img_min: require('../assets/images/letter-1-min.png')
    },
    {
      img: require('../assets/images/letter-2.png'),
      img_min: require('../assets/images/letter-2-min.png')
    },
    {
      img: require('../assets/images/letter-3.png'),
      img_min: require('../assets/images/letter-3-min.png')
    },
    {
      img: require('../assets/images/letter-4.png'),
      img_min: require('../assets/images/letter-4-min.png')
    }
  ]
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
  $_GET[name] = value;
});

Object.keys($_GET).forEach(name => {
  const isTrue = $_GET[name] === '1';

  switch (name) {
    case 'setting-night-mode':
      settings.night_mode = isTrue;
      break;
    case 'setting-show-section-lines':
      settings.show_section_lines = isTrue;
      break;
    case 'setting-sidebar-small':
      settings.sidebar_small = isTrue;
      break;
    case 'setting-sidebar-dark':
      settings.sidebar_dark = isTrue;
      break;
    case 'setting-nav-dark':
      settings.nav_dark = isTrue;
      break;
    // no default
  }
});

export default settings;
