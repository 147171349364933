/**
 * Styles.
 */
import './style.scss';

import React, { useEffect } from 'react';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col, Spinner } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { paymentLinkDetails } from '../../actions/payment-links';
import _ from 'lodash';
import { updatePaginatedData } from '../../utils';
import { GQL_GET_COHORT_SESSION, GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { courseSections } from '../../actions/course';
import { setLandingPromotion } from '../../actions/landing-promotion';

const CohortCourseDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);
  const dispatch = useDispatch();

  const { id } = useParams();
  const { loading, error, data, fetchMore } = useQuery(GQL_GET_COHORT_SESSION, { variables: { id } });
  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  const pl = _.get(data, 'getPaymentLink');
  if (pl) {
    dispatch(paymentLinkDetails(pl));
    dispatch(setLandingPromotion(null));
    dispatch(
      courseSections(
        pl.courseSections
          ?.filter(s => s.status !== 'ARCHIVED')
          .map(section => ({
            ...section,
            courseModules: section.courseModules
              ?.filter(m => m.status !== 'ARCHIVED')
              .slice()
              .sort((a, b) => a.position - b.position)
          }))
          .slice()
          .sort((a, b) => a.position - b.position)
      )
    );
  }

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLink', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl]);

  useEffect(() => {
    if (pl?.id && !pl?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: pl?.id } });
    }
  }, [pl]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );
  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  if (data && data.getPaymentLinkPage && data.getPaymentLinkPage.items.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/cohort-courses': 'Kelas Cohort / Bootcamp'
        }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>{pl && pl.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default CohortCourseDetail;
