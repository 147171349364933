import { gql } from '@apollo/client';

export const GQL_GET_AFFILIATE_USER_PAGE = gql`
  query getAffiliateUserPage(
    $page: Int
    $pageSize: Int
    $search: AffiliateUserSearchInput
    $searchAny: AffiliateUserSearchInput
    $sortField: AffiliateUserSortField
    $sortDirection: SortDirection
  ) {
    getAffiliateUserPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        affiliateUserId
        affiliateProductType
        createdAt
        id
        isActive
        commission
        commissionType
        updatedAt
        userId
        notes
        affiliateUrl
        affiliateUser {
          name
          isAffiliate
        }
        affiliateProduct {
          id
          isActive
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_AFFILIATE_USER_PAGE_BY_NAME = gql`
  query getAffiliateUserPageByName(
    $page: Int
    $pageSize: Int
    $search: AffiliateUserSearchInput
    $searchAny: AffiliateUserSearchInput
    $sortField: AffiliateUserSortField
    $sortDirection: SortDirection
    $searchKeyword: String
  ) {
    getAffiliateUserPageByName(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
      searchKeyword: $searchKeyword
    ) {
      items {
        affiliateUserId
        affiliateProductType
        createdAt
        id
        isActive
        commission
        commissionType
        updatedAt
        userId
        notes
        affiliateUrl
        affiliateUser {
          name
          isAffiliate
        }
        affiliateProduct {
          id
          isActive
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_AFFILIATE_USER_PAGE_TOTAL = gql`
  query getAffiliateUserPage(
    $page: Int
    $pageSize: Int
    $search: AffiliateUserSearchInput
    $searchAny: AffiliateUserSearchInput
    $sortField: AffiliateUserSortField
    $sortDirection: SortDirection
  ) {
    getAffiliateUserPage(
      page: $page
      pageSize: $pageSize
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      nextPage
      offset
      page
      prevPage
      total
      totalPage
    }
  }
`;

export const GQL_INSERT_AFFILIATE_USER = gql`
  mutation insertAffiliateUser($input: AffiliateUserInput) {
    insertAffiliateUser(input: $input) {
      affiliateUserId
      affiliateProductType
      createdAt
      isActive
      commission
      commissionType
      updatedAt
      userId
      notes
      affiliateUrl
      affiliateUser {
        name
      }
    }
  }
`;

export const GQL_UPDATE_AFFILIATE_USER = gql`
  mutation deactivationAffiliateUserWithProducts($affiliateId: ID!) {
    deactivationAffiliateUserWithProducts(affiliateId: $affiliateId) {
      affiliateProductType
      affiliateUrl
      affiliateUserId
      commission
      commissionType
      createdAt
      isActive
      notes
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_AFFILIATE_USER = gql`
  query getAffiliateUser($id: ID) {
    getAffiliateUser(id: $id) {
      affiliateUserId
      affiliateProductType
      createdAt
      id
      commission
      commissionType
      isActive
      updatedAt
      userId
      notes
      affiliateUrl
      affiliateUser {
        email
        name
        mobile
      }
      affiliateProduct {
        affiliateId
        affiliateProductType
        createdAt
        id
        isActive
        merchantId
        productType
        subscriptionId
        paymentLinkId
        updatedAt
        userId
      }
    }
  }
`;

export const GQL_AFFILIATE_USER_WITH_PRODUCT_CREATE = gql`
  mutation insertAffiliateUserWithProducts(
    $affiliate: AffiliateUserInput!
    $products: [AffiliateProductInput]
    $email: String!
  ) {
    insertAffiliateUserWithProducts(affiliate: $affiliate, products: $products, email: $email) {
      affiliateUrl
      affiliateUserId
      affiliateProductType
      createdAt
      commission
      commissionType
      isActive
      email
      id
      notes
      updatedAt
      userId
      affiliateUser {
        email
        name
        mobile
      }
      affiliateProduct {
        affiliateId
        affiliateProductType
        createdAt
        id
        isActive
        merchantId
        productType
        subscriptionId
        paymentLinkId
        updatedAt
        userId
      }
    }
  }
`;

export const GQL_AFFILIATE_USER_WITH_PRODUCT_UPDATE = gql`
  mutation editAffiliateUserWithProducts(
    $affiliate: AffiliateUserInput!
    $products: [AffiliateProductInput]
    $deletedProducts: [AffiliateProductInput]
  ) {
    editAffiliateUserWithProducts(affiliate: $affiliate, products: $products, deletedProducts: $deletedProducts) {
      affiliateUrl
      affiliateUserId
      affiliateProductType
      createdAt
      commission
      commissionType
      isActive
      notes
      updatedAt
      userId
      affiliateUser {
        email
        name
        mobile
      }
      affiliateProduct {
        affiliateId
        affiliateProductType
        createdAt
        id
        isActive
        merchantId
        productType
        subscriptionId
        paymentLinkId
        updatedAt
        userId
      }
    }
  }
`;

export const GQL_ACTIVE_PAYMENT_LINK_GET = gql`
  query getPaymentLinkFilteredList(
    $search: PaymentLinkSearchInput
    $searchAny: PaymentLinkSearchInput
    $sortField: PaymentLinkSortField
    $sortDirection: SortDirection
    $limit: Int
  ) {
    getPaymentLinkFilteredList(
      search: $search
      searchAny: $searchAny
      sortField: $sortField
      sortDirection: $sortDirection
      limit: $limit
    ) {
      id
      name
      status
      userId
      type
      variant
      event {
        id
      }
    }
  }
`;

// replacement of GQL_ACTIVE_PAYMENT_LINK_GET for cross sell modal
export const GET_PAYMENT_LINK = gql`
  query getPaymentLinkFilteredList(
    $search: PaymentLinkSearchInput
    $sortField: PaymentLinkSortField
    $sortDirection: SortDirection
  ) {
    getPaymentLinkFilteredList(search: $search, sortField: $sortField, sortDirection: $sortDirection) {
      id
      name
      status
    }
  }
`;

export const GQL_GET_AFFILIATE_USER_WITH_PRODUCT = gql`
  query getAffiliateUserWithProducts($id: ID) {
    getAffiliateUserWithProducts(affiliateId: $id) {
      affiliateUser {
        affiliateUserId
        affiliateProductType
        createdAt
        id
        commission
        commissionType
        isActive
        updatedAt
        userId
        notes
        affiliateUrl
        totalSales
        totalTransaction
        totalLeads
        affiliateUser {
          email
          name
          mobile
          referralCode
        }
      }
      affiliateProducts {
        affiliateId
        createdAt
        id
        isActive
        paymentLinkId
        productType
        subscriptionId
        updatedAt
        userId
        subscription {
          name
          link
          amount
          status
        }
        merchant {
          name
          paymeLink
          isSelfDomain
          account {
            planType
          }
        }
        paymentLink {
          id
          name
          type
          subType
          link
          amount
          status
        }
        affiliateUser {
          notes
        }
      }
    }
  }
`;

export const GET_AFFILIATE_PRODUCT = gql`
  query getAffiliateProductPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: AffiliateProductSortField
    $search: AffiliateProductSearchInput
    $searchAny: AffiliateProductSearchInput
  ) {
    getAffiliateProductPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
      searchAny: $searchAny
    ) {
      items {
        id
      }
    }
  }
`;

export const GQL_GET_AFFILIATE_PRODUCT_PAGE = gql`
  query getAffiliateProductPage(
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: AffiliateProductSortField
    $search: AffiliateProductSearchInput
    $searchAny: AffiliateProductSearchInput
  ) {
    getAffiliateProductPage(
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
      search: $search
      searchAny: $searchAny
    ) {
      nextPage
      items {
        affiliateId
        createdAt
        id
        isActive
        paymentLinkId
        productType
        subscriptionId
        updatedAt
        userId
        subscription {
          name
          link
          amount
        }
        merchant {
          name
          paymeLink
          isSelfDomain
          account {
            planType
          }
        }
        paymentLink {
          name
          type
          subType
          target
          link
          amount
          status
          order {
            variant
          }
          membershipTier {
            membershipTierPeriod {
              amount
              status
            }
          }
          event {
            ticketEvent {
              amount
            }
          }
        }
        affiliateUser {
          isActive
          notes
          commission
          commissionType
        }
        user {
          referralCode
        }
      }
    }
  }
`;

export const GQL_GET_AFFILIATE_PRODUCT_FILTERED_LIST = gql`
  query getAffiliateProductFilteredList(
    $limit: Int
    $offset: Int
    $search: AffiliateProductSearchInput
    $searchAny: AffiliateProductSearchInput
    $sortDirection: SortDirection
    $sortField: AffiliateProductSortField
  ) {
    getAffiliateProductFilteredList(
      limit: $limit
      offset: $offset
      search: $search
      searchAny: $searchAny
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      affiliateId
      createdAt
      id
      isActive
      paymentLinkId
      productType
      subscriptionId
      updatedAt
      userId
      subscription {
        name
        link
        amount
      }
      merchant {
        name
      }
      paymentLink {
        name
        type
        link
        amount
      }
      affiliateUser {
        notes
      }
      user {
        referralCode
      }
    }
  }
`;

export const GQL_GET_AFFILIATE_PRODUCT = gql`
  query getAffiliateProductPage($page: Int, $pageSize: Int, $search: AffiliateProductSearchInput = {}) {
    getAffiliateProductPage(page: $page, pageSize: $pageSize, search: $search) {
      items {
        affiliateId
        createdAt
        id
        isActive
        paymentLinkId
        productType
        subscriptionId
        updatedAt
        userId
        subscription {
          name
          link
          amount
          status
        }
        merchantId
        merchant {
          name
          paymeLink
          isSelfDomain
          account {
            planType
          }
        }
        paymentLink {
          name
          type
          subType
          target
          link
          amount
          status
          order {
            variant
          }
          membershipTier {
            membershipTierPeriod {
              amount
              status
            }
          }
          event {
            ticketEvent {
              amount
            }
          }
        }
        affiliateUser {
          isActive
          notes
          commission
          commissionType
        }
        user {
          referralCode
        }
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_GET_AFFILIATE_PRODUCT_TRANSCTION_HISTORY = gql`
  query getHistoryTransactionAffiliate(
    $affiliateUserId: ID
    $affiliateProductId: ID
    $paymentLinkId: ID
    $membershipId: ID
    $status: String
    $affiliateId: ID
    $merchantId: ID
  ) {
    getHistoryTransactionAffiliate(
      affiliateUserId: $affiliateUserId
      affiliateProductId: $affiliateProductId
      paymentLinkId: $paymentLinkId
      membershipId: $membershipId
      status: $status
      affiliateId: $affiliateId
      merchantId: $merchantId
    ) {
      totalLeads
      totalSales
      totalTransaction
    }
  }
`;

export const GQL_GET_AFFILIATE_PERFORMANCE = gql`
  query getAffiliatePerformancePage(
    $limit: Int
    $sortDirection: SortDirection
    $sortField: String
    $page: Int
    $pageSize: Int
    $search: BalanceHistorySearchInput!
    $paymentLinkId: ID
    $link: String
  ) {
    getAffiliatePerformancePage(
      limit: $limit
      sortDirection: $sortDirection
      sortField: $sortField
      page: $page
      pageSize: $pageSize
      search: $search
      paymentLinkId: $paymentLinkId
      link: $link
    ) {
      items {
        affiliateId
        merchantId
        paymentLinkId
        userId
        user {
          account {
            logo {
              url
            }
            name
            email
          }
        }
        totalSales
        totalTransaction
        createdAt
      }
      nextPage
      total
    }
  }
`;

export const ASSIGN_AFFILIATE_PRODUCT = gql`
  mutation assignAffiliateProducts($paymentLinkIds: [ID]!, $affiliatorIds: [ID]!) {
    assignAffiliateProducts(paymentLinkIds: $paymentLinkIds, affiliatorIds: $affiliatorIds) {
      status
      errorMessage
    }
  }
`;

export const GET_AFFILIATOR = gql`
  query getAffiliateUserPage(
    $page: Int
    $pageSize: Int
    $search: AffiliateUserSearchInput
    $sortDirection: SortDirection
    $sortField: AffiliateUserSortField
  ) {
    getAffiliateUserPage(
      page: $page
      pageSize: $pageSize
      search: $search
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        affiliateUser {
          email
        }
        affiliateUserId
        isActive
        userId
      }
      nextPage
    }
  }
`;
