import { gql } from '@apollo/client';

export const GQL_UPDATE_TELEGRAM_CHAT_ID = gql`
  mutation updateTelegramChatId($input: UserInput) {
    updateTelegramChatId(input: $input) {
      id
      telegramChatId
      isTelegramLinked
      linkToken
      errorMessage
    }
  }
`;
