/**
 * Styles.
 */
import './style.scss';
import React, { useEffect } from 'react';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Row, Col, Spinner } from 'reactstrap';
import NavigationTopRight from '../../components/navigation-top-right';
import { GQL_GET_SPENDING_PAGE } from '../../gqls';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { paymentLinkDetails } from '../../actions/payment-links';
import _ from 'lodash';
import { updatePaginatedData } from '../../utils';

const BillPaymentDetail = () => {
  const currentUser = useSelector(state => state.currentUser);
  const upl = useSelector(state => state.updatePaymentLinks);
  const dispatch = useDispatch();
  const { id } = useParams();
  const gparam = { search: { id: [{ operator: 'eq', value: id }] } };
  const { loading, error, data, fetchMore } = useQuery(GQL_GET_SPENDING_PAGE, {
    variables: gparam
  });

  const spending = _.get(data, 'getSpendingPage.items[0]');
  if (spending) {
    dispatch(paymentLinkDetails(spending));
  }

  const updateData = () => {
    fetchMore({
      variables: gparam,
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getSpendingPage', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [upl]);

  if (error) return `Error! ${error.message}`;
  if (loading) return <Spinner />;

  if (data?.getSpendingPage?.items?.length === 0) {
    window.location.href = process.env.REACT_APP_BASE_URL;
  }

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{
          '/': currentUser ? currentUser.name : ' ',
          '/bill-payments': 'Pembayaran Invoice',
        }}
      >
        <Row className="mb-0">
          <Col md="6">
            <h1>Pembayaran Invoice #{spending?.invoiceNumber}</h1>
          </Col>
          <Col md="6" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default BillPaymentDetail;
