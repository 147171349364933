import './style.scss';

import React, { useEffect } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spinner } from 'reactstrap';
import { GQL_GET_COURSE, GQL_INSERT_DEFAULT_REMINDER } from '../../gqls';
import { updatePaginatedData } from '../../utils';
import { paymentLinkDetails } from '../../actions/payment-links';
import { courseSections } from '../../actions/course';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import NavigationTopRight from '../../components/navigation-top-right';

const OnlineCourseDetail = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.currentUser);
  const updatePaymentLink = useSelector(state => state.updatePaymentLinks);

  const { id } = useParams();

  const { loading, error, data, fetchMore } = useQuery(GQL_GET_COURSE, {
    variables: {
      search: {
        id: [{ operator: 'eq', value: id }],
        userId: [{ operator: 'eq', value: currentUser.id }]
      }
    }
  });

  const course = _.get(data, 'getPaymentLinkFilteredList[0]');

  if (course) {
    dispatch(paymentLinkDetails(course));

    dispatch(
      courseSections(
        course.courseSections
          .filter(s => s.status !== 'ARCHIVED')
          .map(section => ({
            ...section,
            courseModules: section.courseModules
              ?.filter(m => m.status !== 'ARCHIVED')
              .slice()
              .sort((a, b) => a.position - b.position)
          }))
          .slice()
          .sort((a, b) => a.position - b.position)
      )
    );
  }

  const updateData = () => {
    fetchMore({
      variables: { id },
      updateQuery: (previousResult, { fetchMoreResult }) =>
        updatePaginatedData('getPaymentLinkFilteredList', previousResult, fetchMoreResult),
      notifyOnNetworkStatusChange: true
    });
  };

  useEffect(() => {
    updateData();
  }, [updatePaymentLink]);

  const [insertDefaultReminder] = useMutation(GQL_INSERT_DEFAULT_REMINDER);

  useEffect(() => {
    if (course?.id && !course?.reminderWellcomeId) {
      insertDefaultReminder({ variables: { paymentLinkId: course?.id } });
    }
  }, [course]);

  if (loading)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        <Spinner animation="border" role="status" />
      </div>
    );

  if (error)
    return (
      <div className="fullscreen-div d-flex justify-content-center align-items-center height-100vh">
        {`Error! ${error.message}`}
      </div>
    );

  if (!course) window.location.href = process.env.REACT_APP_BASE_URL;

  return (
    <PageWrap>
      <PageTitle
        className="d-none d-sm-block"
        breadcrumbs={{ '/': currentUser?.name || ' ', '/online-courses': 'Kelas Online' }}
      >
        <Row className="mb-0">
          <Col md="4">
            <h1>Kelas: {course?.name}</h1>
          </Col>
          <Col md="8" className="text-right">
            <NavigationTopRight />
          </Col>
        </Row>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
};

export default OnlineCourseDetail;
